import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { dataDecrypt } from '../app/utils/encryption-helper';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import LoginOtp from './LoginOtp';
import { ApisService } from './../app/core/apis.service';
import { AppConfig } from '../app/config';
import LOGO from './../assets/images/vyapar_logo.svg';



export const Retail = () => {
  const [invoiceData, setinvoiceData] = useState<any>([]);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const userData = useSelector((state: any) => state)

  const routerParams = useParams();
  //time 
  function formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  }
  const created_date: string = formatTimestamp(invoiceData[0]?.created_date);
  console.log(created_date);


  useEffect(() => {
    loadInvoice();
  }, []);

  useEffect(() => {
    if (userData?.UserDataReducer?.otp) {
      setIsOtpEntered(true);
    }
  }, [userData])

  async function loadInvoice(): Promise<void> {
    console.log('routerParams ', routerParams);

    try {
      const res = await ApisService.getRetailData('findInvoiceById', routerParams.id as string)
      console.log('data ', res);
      const data: any = res.data.data;

      const transactionData = JSON.parse(dataDecrypt(data));
      setinvoiceData([...transactionData]);
    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }
  const Invoicedata = () => {
    const invoiceDetails = invoiceData[0]?.invoice_details;
    const invoiceElements = [];
    // for (let i = 0; i < invoiceDetails?.length; i++) {
    const invoicedata = invoiceData[0]?.invoice_details;
    if (invoicedata) {

      const invoice = invoicedata.bill_no;
      const invoicedate = formatTimestamp(invoicedata?.invoice_date);
      const invoicestatus = invoicedata.status;
      if ('ref_invoice_to' in invoicedata) {
        const invoiceElement = (
          <div className='mt-2'>
            <Collapsible trigger={<Card>
              <Card.Body style={{ lineHeight: '0.1' }}>
                <p style={{ color: "#274472" }}>Invoice #{invoice}</p>
                <p>{invoicedate}</p>
                <p>Status:<span style={{ color: "#FF0000" }}>{invoicestatus}</span></p>
              </Card.Body>
            </Card>}>

              <Card>
                <Card.Body style={{ lineHeight: '0.1' }} ><p style={{ color: "#274472" }}>Invoice #{invoicedata.ref_invoice_to.bill_no}</p>
                  <div className='d-flex justify-content-between'>
                    <p >{formatTimestamp(invoicedata.ref_invoice_to.invoice_date)}</p>
                    <p style={{ color: "#274472" }}>{invoicedata.ref_invoice_to.status}</p>
                  </div>
                </Card.Body>
              </Card>
            </Collapsible>

          </div>

        );
        invoiceElements.push(invoiceElement);
      }
      else {
        const invoiceElement = (
          <div className='mt-2'>
            <Card>
              <Card.Body style={{ lineHeight: '0.1' }}>
                <a target='_blank' href={`${AppConfig.BASE_URL}:8089/api/v1/http-utils/retailer-invoice/${invoiceData[0]?.id}`}>
                  <p style={{ color: "#274472" }}>Invoice #{invoice}</p>
                </a>
                <p>{invoicedate}</p>
                <p>Status:<span style={{ color: "#274472" }}>{invoicestatus}</span></p>
              </Card.Body>
            </Card>
          </div>
        );
        invoiceElements.push(invoiceElement);
      }
      // }

    }
    return <div>{invoiceElements}</div>;
  }
  return (
    <div>
      {!isOtpEntered && <LoginOtp reference=''/>}
      {isOtpEntered && <>
        <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#274472", color: "white" }}>
          <Link to={`/order/${routerParams.id}`}><span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Invoice List</h4><div>{invoiceData[0]?.buyer.name}</div></span>
          <span ></span>

        </div>
        <br />
        <div className='container-fluid'>
          <div style={{ backgroundColor: "white", borderRadius: "3px" }} className='mb-4'>
            <Card>
              <Card.Body>
                <div className='row'>
                  <h6 className='col-4'>Name</h6>
                  <span className='col-8'>: {invoiceData[0]?.seller?.name}(Seller)</span>

                </div>


                <div className='row'>
                  <h6 className='col-4'>Txn Id</h6>
                  <span className='col-8'>: {invoiceData[0]?.id}</span>

                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Initiated Date</h6>
                  <div className='col-8'>: {created_date}</div>

                </div>

              </Card.Body>
            </Card>
          </div>

          {Invoicedata()}

        </div>
      </>}
    </div>

  )
}
